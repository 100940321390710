<template>
  <div
    id="agency-edit-modal"
    ref="agency-edit-modal"
    aria-hidden="true"
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ modalTitle }}
          </h5>
          <button
            aria-label="Close"
            class="btn-close"
            data-bs-dismiss="modal"
            type="button"
          />
        </div>
        <div class="modal-body">
          <form v-if="initialized">
            <TextInput
              v-model="item.name"
              :errors="v$.item.name.$errors"
              description="Agency name"
              label="Agency name"
              @blur="v$.item.name.$touch"
            />
            <TextInput
              v-model="item.prefix"
              :errors="v$.item.prefix.$errors"
              description="Prefix"
              label="Prefix"
              @blur="v$.item.prefix.$touch"
            />
            <TextInput
              v-model="item.pteb"
              :errors="v$.item.pteb.$errors"
              description="PTEB"
              label="PTEB"
              @blur="v$.item.pteb.$touch"
            />
          </form>
          <Spinner v-else />
          <div class="modal-footer d-flex justify-content-between">
            <button
              :disabled="isDeleteButtonDisabled"
              class="btn btn-danger"
              @click="showDeleteConfirmationModalHandler"
            >
              Delete
            </button>
            <button
              :disabled="isSaveButtonDisabled"
              aria-label="Close"
              class="btn btn-success"
              type="submit"
              @click="showSaveConfirmationModalHandler"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ConfirmationModal
    ref="agency-edit-confirmation-modal"
    :onCancelHandler="cancelEditHandler"
    :onConfirmHandler="saveChangesHandler"
  >
    <template #content>
      <div
        class="alert alert-warning"
        role="alert"
      >
        <h6>
          <i class="bi bi-exclamation-triangle" />
          The changes you have just made will only
          apply to all new data. Not historical data.
        </h6>
      </div>
    </template>
  </ConfirmationModal>
  <DeleteConfirmationModal
    ref="agency-delete-confirmation-modal"
    :onCancelHandler="cancelDeleteHandler"
    :onConfirmHandler="deleteRecordHandler"
  />
</template>
<script>
import ConfirmationModal from '@/components/common/ConfirmationModal';
import DeleteConfirmationModal from '@/components/common/DeleteConfirmationModal';
import TextInput from '@/components/common/TextInput';
import Spinner from '@/components/common/Spinner';
import { defaultValues } from './constants';
import { mapActions } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { Modal } from 'bootstrap';
import { modalViewTypes } from '@/components/constants';

export default {
  components: {
    ConfirmationModal,
    DeleteConfirmationModal,
    TextInput,
    Spinner
  },
  props: [ 'viewType', 'name' ],
  emits: ['successSave'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      editModal: null,
      confirmationModalSave: null,
      confirmationModalDelete: null,
      initialized: false,
      item: {
        id: null,
        name: null,
        prefix: null,
        pteb: null
      }
    };
  },
  validations() {
    return {
      item: {
        name: { required },
        prefix: { required },
        pteb: { required }
      }
    };
  },
  computed: {
    modalTitle() {
      return this.viewType === modalViewTypes.edit ? 'Edit row' : 'Add New';
    },
    isSaveButtonDisabled() {
      return !this.initialized;
    },
    isDeleteButtonDisabled() {
      return !this.initialized || this.viewType === modalViewTypes.add;
    }
  },
  async mounted() {
    this.editModal = new Modal(this.$refs['agency-edit-modal']);
    this.confirmationModalSave = this.$refs['agency-edit-confirmation-modal'].confirmationModal;
    this.confirmationModalDelete = this.$refs['agency-delete-confirmation-modal'].confirmationModal;
    this.confirmationModalSave.hide();
    this.confirmationModalDelete.hide();
  },
  methods: {
    ...mapActions({
      fetchAgencyItems: 'agency/fetchAgencyItems',
      fetchAgencyItemById: 'agency/fetchAgencyItemById',
      createAgencyItem: 'agency/createItem',
      updateAgencyItem: 'agency/updateItem',
      deleteAgencyItem: 'agency/deleteItem'
    }),
    async initModal(itemId) {
      this.initialized = false;
      this.editModal.show();
      const itemToEdit = itemId ? await this.fetchAgencyItemById(itemId) : { ...defaultValues };
      if (!itemId && this.name) {
        itemToEdit.name = this.name;
      }
      this.updateItemData(itemToEdit);
      this.initialized = true;
    },
    updateItemData(newValue) {
      this.item.id = newValue.id;
      this.item.name = newValue.name;
      this.item.prefix = newValue.prefix;
      this.item.pteb = newValue.pteb;
    },
    showDeleteConfirmationModalHandler() {
      this.editModal.hide();
      this.confirmationModalDelete.show();
    },
    cancelDeleteHandler() {
      this.confirmationModalDelete.hide();
      this.editModal.show();
    },
    async deleteRecordHandler() {
      try {
        await this.deleteAgencyItem(this.item.id);
        await this.fetchAgencyItems();
      } catch (e) {
        this.$toast.error('Failed to delete item.');
      }
    },
    async showSaveConfirmationModalHandler() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        this.$toast.error('Form should be valid.');
        return false;
      }
      this.editModal.hide();
      this.confirmationModalSave.show();
    },
    async saveChangesHandler() {
      const dataToSave = {
        ...this.item
      };
      try {
        if (dataToSave.id) {
          await this.updateAgencyItem(dataToSave);
          this.$toast.success('Item successfully updated');
        } else {
          await this.createAgencyItem(dataToSave);
          this.$toast.success('Item successfully created');
        }
        await this.fetchAgencyItems();
        this.$emit('successSave');
      } catch (e) {
        const message = e.message ? `: ${e.message}` : '';
        this.$toast.error(`Failed to save the data${message}.`);
      }
    },
    cancelEditHandler() {
      this.confirmationModalSave.hide();
    }
  }
};
</script>
