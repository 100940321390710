export const defaultValues = {
  id: null,
  name: null,
  prefix: null,
  pteb: 0.253
};

export const agencyGreedColumns = ({ rowClickHandler }) => [
  {
    headerName: '',
    field: 'id',
    cellRenderer: 'AgGreedButtonCellRenderer',
    cellRendererParams: {
      clicked: (field) => {
        rowClickHandler(field);
      }
    },
    width: 80
  },
  {
    headerName: 'Id',
    field: 'id',
    width: 100
  },
  {
    headerName: 'Agency name',
    field: 'name',
    width: 250
  },
  {
    headerName: 'Prefix',
    field: 'prefix',
    width: 200
  },
  {
    headerName: 'PTEB',
    field: 'pteb',
    width: 200
  }
];
