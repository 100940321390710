<template>
  <div class="container mt-5">
    <div class="card-body p-0 w-100 d-flex justify-content-center">
      <div class="col col-8">
        <div class="card">
          <div class="card-header d-flex justify-content-between">
            <h5 class=" mt-1 mb-0">
              Agency Configuration
            </h5>
            <button
              class="btn btn-sm btn-primary"
              @click="addNewClickHandler"
            >
              Add new
            </button>
          </div>
          <div
            class="card-body p-0"
            style="height: 50em"
          >
            <ag-grid-vue
              :columnDefs="columnDefs"
              :defaultColDef="defaultColDef"
              :rowData="rowData"
              class="ag-theme-alpine w-100 h-100 slaEditModal"
            />
          </div>
        </div>
      </div>
      <AgencyEditModal
        ref="agencyEditModal"
        :viewType="modalViewType"
      />
    </div>
  </div>
</template>
<script>
import { AgGridVue } from 'ag-grid-vue3';
import { mapActions, mapGetters } from 'vuex';
import AgencyEditModal from './AgencyEditModal';
import { modalViewTypes } from '@/components/constants';
import AgGreedButtonCellRenderer from '@/components/common/AgGreedButtonCellRenderer';
import { agencyGreedColumns } from './constants';

export default {
  components: {
    AgGridVue,
    AgencyEditModal,
    // eslint-disable-next-line vue/no-unused-components
    AgGreedButtonCellRenderer
  },
  data() {
    return {
      modalViewType: modalViewTypes.edit
    };
  },
  computed: {
    ...mapGetters({
      rowData: 'agency/agencyItems'
    }),
    defaultColDef() {
      return {
        resizable: true,
        initialWidth: 200,
        wrapHeaderText: true,
        autoHeaderHeight: true
      };
    },
    columnDefs() {
      return agencyGreedColumns({
        rowClickHandler: this.rowClickHandler
      });
    }
  },
  async mounted() {
    await this.fetchAgencyItems();
  },
  methods: {
    ...mapActions({
      fetchAgencyItems: 'agency/fetchAgencyItems'
    }),
    async rowClickHandler(itemId) {
      this.modalViewType = modalViewTypes.edit;
      await this.$refs.agencyEditModal.initModal(itemId);
    },
    addNewClickHandler() {
      this.modalViewType = modalViewTypes.add;
      this.$refs.agencyEditModal.initModal();
    }
  }
};
</script>
